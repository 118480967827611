<template>
  <div>
      <!-- v-else-if="panelForm.controlBox.indexOf(panelForm.devSecondType) > -1" -->
    <el-form :model="controlObj"  ref="controlBox" label-width="100px" class="control-Box">
        <el-row>
            <el-col :span="24" align="center" class="controlBox-title">
                设备已{{ controlObj.state=="88"?"开启":"关闭" }}
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24" align="center">
                <div v-bind:class="[controlObj.state=='88'?'color1':'color2','controlBox-iconBox']">
                    <Power theme="outline" size="60" :strokeWidth="3" fill="#ffffff" strokeLinecap="square" class="icon"/>
                </div>
            </el-col>
        </el-row>
        <el-row class="hint">
            <el-col align="center" :span="23" :push="1" v-if="controlObj.delayTime&&controlObj.actionTime">
                控制成功时，延时{{ controlObj.delayTime }}秒钟后执行开，执行成功{{ controlObj.actionTime }}秒钟后执行关。
            </el-col>
            <el-col align="center" :span="23" :push="1" v-else-if="controlObj.delayTime">
                控制成功时，延时{{ controlObj.delayTime }}秒钟后执行开。
            </el-col>
            <el-col align="center" :span="23" :push="1" v-else-if="controlObj.actionTime">
                控制成功时，执行成功{{ controlObj.actionTime }}秒钟后执行关。
            </el-col>
            <el-col align="center" :span="24" v-else>
                立即执行
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="11" align="right">
                <el-button type="success" @click="controlBoxOpen">开</el-button>
            </el-col>
            <el-col :span="11" :push="2" align="left">
                <el-button type="success" @click="controlBoxClose">关</el-button>
            </el-col>
        </el-row>
        <el-row>
             <el-col :span="9" align="right">
                <el-button type="info" @click="controlObjStting">设置</el-button>
            </el-col>
            <el-dialog title="设置" :visible.sync="controlObj.dialog" class="controlObjSttingBox" append-to-body width="550px">
                <ControlBoxSetUp ref="ControlBoxSetUp" :controlObj="controlObj" :controlBoxOpen="controlBoxOpen"></ControlBoxSetUp>
            </el-dialog>
            <el-col :span="6" align="center">
            <el-button type="info" @click="pairEq">配对</el-button>
            </el-col>
            <el-col :span="9" align="left">
            <el-button type="info" @click="positioning">定位</el-button>
            </el-col>
        </el-row>
    </el-form>
  </div>
</template>

<script>
import { Power } from '@icon-park/vue'
import ControlBoxSetUp from './controlBoxSetUp/controlBoxSetUp.vue'
 
export default {
    props:{
        controlObj:{
            type:Object,
        },
        pairEq:{
            type:Function
        },
        positioning:{
            type:Function
        },
        id:Number|String
    },
    components: {
        Power,
        ControlBoxSetUp
    },
    data(){
        return{
             
        }
    },
    methods:{
        
        controlObjStting(){
            this.controlObj.dialog=true
        },
        controlBoxOpen(){
            this.controlHandle('开')
        },
        controlBoxClose(){
            this.controlHandle('关')
        },
        controlHandle( state ){
        let {delayTime,actionTime} =this.controlObj
        delayTime=parseInt(delayTime)
        actionTime=parseInt(actionTime)
        console.log(delayTime,actionTime);
            this.$axios({
                method: "post",
                url: '/common2/devices/operate',
                data: {
                    devId:this.id,
                    devState: state,
                    delayTime,actionTime
                }
            }).then((res)=>{
                let result =  JSON.parse(res.data.result)
                console.log(result)
                let { status } = result.data[0]
                if(result.data[0].resultCode){
                    this.$message.warning(result.data[0].resultMsg)
                    return
                }
                this.$message.success('控制成功')
                this.controlObj.state = status.slice(6,8)
            }).catch(err=>{
                // console.log(err.response)
                this.isError(err, this)
            })
        
        },
    }
}
</script>

<style lang="scss" scoped>
.control-Box{
    .hint{
        font-weight:bold;
        color:#7ed321;
    }
    .el-row{
        margin-bottom: 15px;
    }
    .el-button{
        width:100px;
        height:30px;
        font-size:16px;
        padding:0;
    }
    .controlBox-title{
        font-weight: bold;
    }
    .controlBox-iconBox{
        width:90px;
        height:90px;
        border-radius:50%;
        position:relative;
        .icon{
            position: absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%)
        }
    }
    .color1{
        background-color:#7ed321;
    }
    .color2{
        background-color:#999;
    }
}
</style>
